<template>
  <div class="select">
    <div
      class="select__selected"
      ref="selectBtn"
      @click="open = !open"
      :class="{ open: open, selectStyle: type === 'select' }"
    >
      <input @click.stop="open = true" :placeholder="placeholder" @input="search($event.target.value)" class="select__title" type="text" v-model="selected_title" />
      
      <!-- <span v-if="selected.img"><img :src="selected.img" alt="" /></span> -->
      <span v-for="item in new_options" :key="item"
        ><img
          style="width: 32px; height: 21px"
          v-if="item.value == lang"
          :src="item.img"
          alt=""
      /></span>
      <span v-if="type === 'select' && style === 'gray'" class="select__arrow"
        ><img src="../assets/icons/shopping-cart/arrow.svg" alt=""
      /></span>
      <span v-if="type === 'select' && style === 'black'" class="select__arrow"
        ><img src="../assets/icons/catalog/black-arrow.svg" alt=""
      /></span>
      <span v-if="type === 'lang'" class="select__arrow select__arrow_lang"
        ><img src="../assets/icons/header/gray-arrow.svg" alt=""
      /></span>
    </div>
    <transition name="fade">
      <div class="select__content" v-if="open" v-click-out-side="hideSelect">
        <ul
          class="select__list"
          :class="{ selectStyle: type === 'select', lang: type === 'lang' }"
        >
          <li
            v-for="(option, idx) in new_options"
            :key="idx"
            @click="selectOption(option)"
          >
            <span v-if="option.title">{{ option.title }}</span>
            <span v-if="option.img && option.value !== lang"
              ><img :src="option.img" alt=""
            /></span>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
import clickOutSide from "@mahdikhashan/vue3-click-outside";
import { mapGetters } from "vuex";

export default {
  name: "custom-select",
  directives: {
    clickOutSide,
  },
  props: {
    options: {
      type: Array,
    },
    selectedOption: {
      type: Object,
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      required: false,
      default: "select",
    },
    clear: {
      type: Boolean,
      required: false,
    },
    style: {
      type: String,
      required: false,
      default: "gray",
    },
  },
  data() {
    return {
      lang: "",
      selected: this.selectedOption
        ? this.selectedOption
        : '',
      open: false,
      new_options: this.options,
      selected_title: this.selectedOption
        ? this.selectedOption.title
        : '',
    };
  },
  methods: {
    hideSelect(e) {
      const selectBtn = this.$refs.selectBtn;
      if (!(e.target == selectBtn || selectBtn.contains(event.target))) {
        this.open = false;
      }
    },
    selectOption(option) {
      this.selected = option;
      this.open = false;
      this.selected_title = option.title
      this.$emit("select", option);
    },
    search(value) {
      this.new_options = this.options.filter(obj => obj.title.toLowerCase().includes(value.toLowerCase()));
    }
  },
  mounted() {
    this.lang = this.getLang;
  },
  computed: {
    ...mapGetters(["getLang"]),
  },
  watch: {
    clear: function () {
      if (this.clear) this.selected = this.placeholder;
    },
    selectedOption() {
      if (this.selectedOption) {
        this.selected = this.selectedOption;
      } else this.selected = this.placeholder;
    },
  },
};
</script>

<style lang="scss" scoped>
ul li {
  list-style: none;
}
.select {
  display: inline-block;
  position: relative;
  //z-index: 3;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #77757f;
  &__title {
    padding: 18px 20px;
    border-radius: 10px;
    width: 100%;
  }
  &__list {
    position: absolute;
    background: #ffffff;
    max-height: 300px;
    overflow-y: auto;
    li {
      cursor: pointer;

      &:not(:last-child) {
        margin: 0 0 5px 0;
      }
    }

    &.lang {
      top: calc(100% + 5px);

      img {
        width: 32px;
        height: 21px;
      }
    }

    &.selectStyle {
      top: calc(100% + 10px);
      border: 1px solid #e0e0e0;
      border-radius: 10px;
      left: -1px;
      width: calc(100% + 2px);
      padding: 10px 20px;
    }
  }

  &__selected {
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.selectStyle {
      width: 100%;
      border: 1px solid #e0e0e0;
      border-radius: 10px;
      padding-right: 20px;
    }

    &.open {
      .select__arrow {
        transform: rotate(180deg);
      }
    }
  }

  &__arrow {
    width: 10px;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease 0s;

    &_lang {
      margin: 0 0 0 2px;
      width: 20px;
      height: 20px;
    }
  }
}
</style>