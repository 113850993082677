<template>
  <div class="shopping-cart">
    <div class="_container">
      <BreadCrumbs
        :items="[
          [$t('main'), '/'],
          [`${$t('basket')} - ${$t('checkout.title')}`],
        ]"
      />
    </div>
    <div class="shopping-cart__container _container">
      <div class="shopping-cart__cart">
        <h1 class="shopping-cart__title main-title">{{ $t("basket") }}</h1>
        <div
          class="shopping-cart__second-title base-text"
          v-if="cart.length === 0"
        >
          {{ $t("emptyBasket") }}
        </div>
        <div class="shopping-cart__items">
          <div
            class="shopping-cart__item"
            v-for="(item, idx) in cart"
            :key="idx"
          >
            <CartItem
              @typeBuy="typeBuy"
              @counter="onCounter"
              @boolBonus="boolBonus"
              @delete="deleteValues"
              :item="item"
              :title="serverCard[idx]"
            ></CartItem>
          </div>
        </div>
        <router-link to="/">
          <button class="shopping-cart__btn main-btn">
            {{ $t("continueShopping") }}
          </button></router-link
        >
      </div>

      <div class="shopping-cart__order order-cart" v-show="auth && cart.length > 0">
        <h1 class="order-cart__title main-title">{{ $t("checkout.title") }}</h1>
        <VeeForm @submit="createOrder" @invalidSubmit="onInvalidSubmit" class="order-cart__form">
          <div class="order-cart__section">
            <h2 class="order-cart__second-title">{{ $t("checkout.data") }}</h2>
            <div class="order-cart__items">
              <div class="order-cart__item">
                <VeeField
                  name="name"
                  rules="required"
                  type="text"
                  class="order-cart__input main-input"
                  :placeholder="$t('placeholder.name')"
                  v-model="order.name"
                />
                <transition name="error">
                  <VeeErrorMessage
                    name="name"
                    class="error-message"
                  ></VeeErrorMessage>
                </transition>
              </div>
              <div class="order-cart__item" v-if="typeUser != 'entity'">
                <VeeField
                  name="sname"
                  rules="required"
                  type="text"
                  class="order-cart__input main-input"
                  :placeholder="$t('placeholder.surname')"
                  v-model="order.surname"
                />
                <transition name="error">
                  <VeeErrorMessage
                    name="sname"
                    class="error-message"
                  ></VeeErrorMessage>
                </transition>
              </div>
              <div class="order-cart__item">
                <VeeField
                  name="email"
                  rules="required|email"
                  type="email"
                  class="order-cart__input main-input"
                  placeholder="E-mail*"
                  v-model="order.email"
                />
                <transition name="error">
                  <VeeErrorMessage
                    name="email"
                    class="error-message"
                  ></VeeErrorMessage>
                </transition>
              </div>
              <div class="order-cart__item">
                <VeeField
                  name="phone"
                  rules="required"
                  type="tel"
                  class="order-cart__input main-input"
                  :placeholder="$t('placeholder.phone')"
                  v-model="order.phone"
                />
                <transition name="error">
                  <VeeErrorMessage
                    name="phone"
                    class="error-message"
                  ></VeeErrorMessage>
                </transition>
              </div>
            </div>
          </div>

          <div class="order-cart__section">
            <div class="order-cart__block">
              <TabItem
                :tabItems="tabs"
                :selected="selectedTab"
                @setTab="selectTab"
                :type="'border'"
              ></TabItem>
              <TabBlock :isSelected="selectedTab === tabs[0].id">
                <h3 class="order-cart__sub-title">
                  {{ $t("checkout.address") }}
                </h3>

                <div
                  class="order-cart__addresses"
                  v-if="user.addresses && !showAddressForm"
                >
                  <div class="order-cart__wrapper">
                    <div
                      class="order-cart__address"
                      v-for="(address, id) in user.addresses"
                      :key="id"
                    >
                      <label
                        :for="address.id"
                        class="order-cart__radio"
                        @click="showAddressForm = false"
                      >
                        <span
                          class="order-cart__icon"
                          :class="{
                            active: selectedAddress.id === parseInt(address.id),
                          }"
                        ></span>
                        <span class="order-cart__map"
                          ><img
                            src="../assets/icons/shopping-cart/map.svg"
                            alt=""
                        /></span>
                        <VeeField
                          type="radio"
                          name="address"
                          label="Address"
                          rules="selected"
                          :id="address.id"
                          :value="address"
                          v-model="selectedAddress"
                        />
                        {{ formatAddress(address) }}
                      </label>
                    </div>
                  </div>
                  <transition name="error">
                    <VeeErrorMessage
                      name="address"
                      class="error-message"
                    ></VeeErrorMessage>
                  </transition>
                </div>
                <div
                  class="order-cart__items order-cart__items_second"
                  v-if="showAddressForm"
                >
                  <div class="order-cart__item">
                    <SelectComponent
                      class="order-cart__select order-cart__select_first"
                      @select="selectRegion"
                      :type="'select'"
                      :options="regions"
                      :placeholder="$t('placeholder.region')"
                    />
                  </div>
                  <div class="order-cart__item">
                    <SelectComponent
                      class="order-cart__select"
                      @select="selectCity"
                      :options="cities"
                      :type="'select'"
                      :placeholder="$t('placeholder.city')"
                    />
                  </div>
                  <div class="order-cart__item order-cart__item_address">
                    <VeeField
                      name="street"
                      rules="required"
                      type="text"
                      class="order-cart__input main-input"
                      :placeholder="$t('placeholder.street')"
                      v-model="selectedAddress.address"
                    />
                    <transition name="error">
                      <VeeErrorMessage
                        name="street"
                        class="error-message"
                      ></VeeErrorMessage>
                    </transition>
                  </div>
                  <div class="order-cart__item order-cart__item_flat">
                    <VeeField
                      name="apartment"
                      type="text"
                      class="order-cart__input main-input"
                      :placeholder="$t('placeholder.kv')"
                      v-model="selectedAddress.apartment"
                    />
                    <transition name="error">
                      <VeeErrorMessage
                        name="apartment"
                        class="error-message"
                      ></VeeErrorMessage>
                    </transition>
                  </div>
                  <div class="order-cart__example">
                    {{ $t("checkout.primer") }}
                  </div>
                </div>
                <button
                  class="order-cart__add-address main-btn"
                  type="button"
                  @click="addAddress"
                  v-if="auth"
                >
                  <span v-if="!showAddressForm">{{
                    $t("buttons.addAddress")
                  }}</span>
                  <span v-else>{{ $t("buttons.deleteAddress") }}</span>
                </button>
              </TabBlock>
              <TabBlock :isSelected="selectedTab === tabs[1].id">
                <h3 class="order-cart__sub-title">
                  {{ $t("checkout.ourShops") }}
                </h3>
                <div
                  class="order-cart__address"
                  v-for="(address, id) in shops"
                  :key="id"
                >
                  <label
                    :for="address.id"
                    class="order-cart__radio"
                    @click="showAddressForm = false"
                  >
                    <span
                      class="order-cart__icon"
                      :class="{
                        active: selectedAddress.id === parseInt(address.id),
                      }"
                    ></span>
                    <span class="order-cart__map"
                      ><img src="../assets/icons/shopping-cart/map.svg" alt=""
                    /></span>
                    <VeeField
                      name="shop"
                      label="Address"
                      rules="selected"
                      type="radio"
                      :id="address.id"
                      :value="address"
                      v-model="selectedAddress"
                    />
                    {{ address.city_name + " " + address.adds }}
                  </label>
                </div>
                <transition name="error">
                  <VeeErrorMessage
                    name="shop"
                    class="error-message"
                  ></VeeErrorMessage>
                </transition>
              </TabBlock>
            </div>

            <div class="order-cart__block">
              <h3 class="order-cart__sub-title">
                {{ $t("checkout.typePayment") }}
              </h3>
              <div class="order-cart__payments">
                <!-- <div class="order-cart__payment">
                  <label
                    class="label"
                    :class="{ active: order.payment === 'online' }"
                    for="online"
                    >{{ $t("checkout.cart") }}</label
                  >
                  <VeeField
                    rules="required"
                    type="radio"
                    id="online"
                    value="online"
                    name="payment"
                    v-model="order.payment"
                  />
                </div> -->
                <div class="order-cart__payment">
                  <label
                    for="cash"
                    :class="{ active: order.payment === 'cash' }"
                    >{{ $t("checkout.cash") }}</label
                  >
                  <VeeField
                    rules="required"
                    type="radio"
                    id="cash"
                    value="cash"
                    name="payment"
                    v-model="order.payment"
                  />
                </div>
              </div>
              <transition name="error">
                <VeeErrorMessage
                  name="payment"
                  class="error-message"
                ></VeeErrorMessage>
              </transition>
            </div>

            <div class="order-cart__block">
              <h3
                class="order-cart__sub-title"
                v-if="order.deliveryType === 'delivery'"
              >
                {{ $t("checkout.dateDelivery") }}
              </h3>
              <h3 class="order-cart__sub-title" v-else>
                {{ $t("checkout.dateIssue") }}
              </h3>
              <VeeField
                name="date"
                :min="today"
                max="2050-12-31"
                type="date"
                @keyup="todayError()"
                class="order-cart__date main-input"
                v-model="order.deliveryDate"
              />
              <transition name="error">
                <VeeErrorMessage
                  name="date"
                  class="error-message"
                ></VeeErrorMessage>
              </transition>
            </div>

            <div class="order-cart__block">
              <h3 class="order-cart__sub-title">
                {{ $t("checkout.comment") }}
              </h3>
              <VeeField
                name="comment"
                v-model="order.comment"
                v-slot="{ field }"
              >
                <textarea
                  class="order-cart__textarea main-input"
                  :placeholder="$t('placeholder.comment')"
                  v-bind="field"
                  name="comment"
                />
              </VeeField>
              <transition name="error">
                <VeeErrorMessage
                  name="comment"
                  class="error-message"
                ></VeeErrorMessage>
              </transition>
            </div>
          </div>

          <div class="order-cart__section">
            <div class="order-cart__coupon shopping-total">
              <h2 class="shopping-total__title">
                {{ $t("checkout.useCupone") }}
              </h2>
              <div v-if="modalShow != ''" name="comment" class="error-message">
                {{ modalShow }}
              </div>
              <div class="shopping-total__coupon">
                <input
                  type="text"
                  class="shopping-total__input"
                  v-model="promocode"
                  :placeholder="$t('placeholder.cupone')"
                />
                <button
                  class="shopping-total__btn"
                  type="button"
                  @click="getDiscount"
                >
                  {{ $t("checkout.apply") }}
                </button>
              </div>
              <div class="shopping-total__items">
                <div class="shopping-total__item">
                  <div class="shopping-total__second-title">
                    {{ $t("checkout.goods") }}
                  </div>
                  <div class="shopping-total__amount">
                    {{ formatPrice(cartTotal) }}
                  </div>
                </div>
                <div class="shopping-total__item" v-if="user.discount">
                  <div class="shopping-total__second-title">
                    {{ $t("checkout.discount") }}
                  </div>
                  <div class="shopping-total__amount">{{ user.discount }}%</div>
                </div>
                <div class="shopping-total__item" v-if="totalBonus">
                  <div class="shopping-total__second-title">
                    {{ $t("checkout.bonus") }}
                  </div>
                  <div class="shopping-total__amount">
                    {{ formatPrice(totalBonus) }}
                  </div>
                </div>
                <!-- <div class="shopping-total__item" v-if="deliveryCost != 0">
                  <div class="shopping-total__second-title">
                    {{ $t("checkout.courier") }}
                  </div>
                  <div class="shopping-total__amount">
                    {{ formatPrice(deliveryCost) }}
                  </div>
                </div> -->
                <div class="shopping-total__item" v-if="showDiscount">
                  <div class="shopping-total__second-title">
                    {{ $t("checkout.discount") }}
                  </div>
                  <div class="shopping-total__amount">{{ discount }} %</div>
                </div>
              </div>
              <div class="shopping-total__total">
                <div>{{ $t("checkout.sum") }}</div>
                <div>{{ formatPrice(total) }}</div>
              </div>
              <div class="shopping-total__deliveryText"  v-if="typeUser != 'entity'">
                <img :src="require('../assets/icons/info-circle.svg')" alt="info-circle.svg" />
                <div>{{ $t("checkout.deliveryText") }}</div>
              </div>
            </div>
            <div class="order-cart__accept">
              <div class="order-cart__group">
                <VeeField
                  name="accept"
                  rules="required"
                  value="accept"
                  type="checkbox"
                  class="order-cart__checkbox main-checkbox"
                  id="checkbox"
                />
                <label
                  for="checkbox"
                  class="order-cart__text"
                  v-html="$t('uslovie1')"
                ></label>
              </div>
              <transition name="error">
                <VeeErrorMessage
                  name="accept"
                  class="error-message"
                ></VeeErrorMessage>
              </transition>
            </div>

            <button type="submit" class="order-cart__btn main-btn">
              {{ $t("checkout.confirm") }}
            </button>
          </div>
        </VeeForm>
      </div>
      <div
        class="shopping-cart__order order-cart"
        v-show="!auth"
        v-html="$t('oformlenie')"
      ></div>
    </div>
  </div>
</template>

<script>
import CartItem from "@/components/CartItem";
import TabItem from "@/components/TabItem";
import TabBlock from "@/components/TabBlock";
import SelectComponent from "@/components/SelectComponent";
import { mapActions, mapGetters, mapMutations, mapState } from "vuex";
import axios from "axios";
import BreadCrumbs from "../components/UI/BreadCrumbs.vue";
import { useToast } from "vue-toastification";

export default {
  components: { SelectComponent, TabBlock, TabItem, CartItem, BreadCrumbs },
  data() {
    return {
      selectedAddress: false,
      shops: [],
      showAddressForm: false,
      showDiscount: false,
      selectedTab: null,
      deliveryCost: null,
      promocode: "",
      discount: 0,
      boolCount: [],
      typeUser: null,
      totalBonus: 0,
      typePromo: "",
      freeCount: [],
      today: '',
      modalShow: "",
      cards: null,
      serverCard: [],
      tabs: [
        { id: 1, name: "nav.delivery" },
        { id: 2, name: "pickup" },
      ],
      order: {
        name: "",
        surname: "",
        email: "",
        phone: "",
        deliveryType: "",
        deliveryDate: "",
        comment: "",
        payment: "cash",
      },
    };
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  computed: {
    ...mapState(["cart", "user", "regions", "cities", "auth"]),
    ...mapGetters(["cartTotal"]),
    total() {
      if (this.auth) {
        return (
          this.cartTotal -
          this.userDiscount -
          this.promoCodeDiscount 
          // + this.deliveryCost
        );
      } else {
        return this.cartTotal - this.promoCodeDiscount
        // + this.deliveryCost
      }
    },
    promoCodeDiscount() {
      return (this.cartTotal * this.discount) / 100;
    },
    userDiscount() {
      return (this.cartTotal * this.user.discount) / 100;
    },
  },
  async created() {
    this.fetchRegions();
    this.getShops();
    this.getDeliveryCost();
    this.getUser();
    this.requestUser();
    this.selectedTab = this.tabs[0].id;
    this.cards = this.cart;
    var funds1, funds2;
    await axios
      .get("V1/funds")
      .then((response) => {
        funds1 = response.data.funds[0];
        funds2 = response.data.funds[1];
      })
      .catch((error) => {
      });
    await this.cards.map((item) => {
      axios
        .get(`V1/product-by-slug`, {
          params: {
            slug: item.slug,
          },
          headers: {
            Authorization: localStorage.getItem("access_token")
              ? `Bearer ${localStorage.getItem("access_token")}`
              : null,
          },
        })
        .then((response) => {
          this.serverCard.push(response.data.data.title);
        });
      item.funds_1 == 1 ? this.addToCart1({ ...item, funds1: funds1 }) : null;
      item.funds_2 == 1 ? this.addToCart1({ ...item, funds2: funds2 }) : null;
    });
  },
  methods: {
    ...mapMutations(["SET_CART", "addToCart", "addToCart1"]),
    ...mapActions(["fetchRegions", "fetchCities", "requestUser"]),
    deleteValues(data) {
      this.boolCount.forEach((item, idx, object) => {
        if (item.id === data.id) {
          object.splice(idx, 1);
        }
      });

      this.freeCount.forEach((item, idx, object) => {
        if (item.id === data.id) {
          object.splice(idx, 1);
        }
      });
    },

    todayError(value) {
      if(this.order.deliveryDate !== '') {
        this.order.deliveryDate.replace(/[^0-9]/g,"") < this.today.replace(/[^0-9]/g,"") ? this.order.deliveryDate = "" : ''
      }
    },
    getDate() {
      let today1 = new Date();
      let dd = today1.getDate();
      let mm = today1.getMonth()+1; //January is 0!
      let yyyy = today1.getFullYear();
      if(dd<10) {
          dd = '0'+dd
      }
      if(mm<10) {
          mm = '0'+mm
      }
      this.today = yyyy + '-' + mm + '-' + dd;
    },
    typeBuy(value) {},
    boolBonus(data) {
      let idx = 0;
      this.totalBonus = 0;
      if (this.boolCount.length) {
        this.boolCount.forEach((item) => {
          if (item.id === data.id) {
            item.funds_2 = data.bool;
            item.count = data.count;
            item.bonus = data.bonus;
            item.typeBuy = data.typeBuy;
            idx++;
          }
        });
        idx === 0
          ? this.boolCount.push({
              id: data.id,
              count: data.count,
              funds_2: data.bool,
              bonus: data.bonus,
              typeBuy: data.typeBuy,
            })
          : idx++;
      } else {
        this.boolCount.push({
          id: data.id,
          count: data.count,
          funds_2: data.bool,
          typeBuy: data.typeBuy,
          bonus: data.bonus,
        });
      }
      this.boolCount.map((item) => {
        item.funds_2 === 1 ? (this.totalBonus += item.bonus) : this.totalBonus;
      });
    },
    onCounter(data) {
      let idx = 0;
      if (this.freeCount.length) {
        this.freeCount.forEach((item) => {
          if (item.id === data.id) {
            item.count = data.count;
            item.quant = data.quant;
            item.funds_1 = data.funds_1;
            item.typeBuy = data.typeBuy;
            idx++;
          }
        });
        idx === 0
          ? this.freeCount.push({
              id: data.id,
              count: data.count,
              quant: data.quant,
              price: data.price,
              funds_1: data.funds_1,
              typeBuy: data.typeBuy,
            })
          : idx++;
      } else {
        this.freeCount.push({
          id: data.id,
          count: data.count,
          quant: data.quant,
          price: data.price,
          typeBuy: data.typeBuy,
          funds_1: data.funds_1,
        });
      }
    },
    selectTab(tab) {
      tab === 2 || localStorage.getItem('type') === 'entity' ? (this.deliveryCost = 0) : this.getDeliveryCost();
      this.selectedTab = tab;
    },
    selectRegion(option) {
      this.selectedAddress.region_id = option.id;
      this.fetchCities(this.selectedAddress.region_id);
    },
    selectCity(option) {
      this.selectedAddress.city_id = option.id;
    },
    addAddress() {
      this.selectedAddress = {};
      this.showAddressForm = !this.showAddressForm;
    },
    formatPrice(currency) {
      return currency?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " ₸";
    },
    formatAddress(address) {
      return (
        address.region_name +
        ", " +
        address.city_name +
        ", " +
        address.address +
        ", кв. " +
        address.apartment
      );
    },
    getDiscount() {
      axios
        .get("V1/promocode", {
          params: {
            code: this.promocode,
            price: this.cartTotal,
          },
          headers: {
            Authorization: localStorage.getItem("access_token")
              ? `Bearer ${localStorage.getItem("access_token")}`
              : null,
          },
        })
        .then((response) => {
          this.cartTotal = response.data.new_price;
          this.discount = response.data.percent;
          this.typePromo = response.data.type;
          this.showDiscount = true;
          this.modalShow = "";
        })
        .catch((error) => {
          this.discount = 0;
          this.showDiscount = false;
          this.modalShow = error.response.data.message;
        });
    },
    getDeliveryCost() {
      if (localStorage.getItem('type') === 'entity') {
          this.deliveryCost = 0
      } else {
        axios
          .get("V1/delivery-price")
          .then((response) => {
            this.deliveryCost = response.data.price;
          })
          .catch((error) => {
          });

      }
    },
    getShops() {
      axios
        .get(`V1/addresses`)
        .then((response) => {
          this.shops = response.data.data;
        })

        .catch((error) => {
        });
    },

    getUser() {
      if (this.auth) {
        this.order.name = this.user.name;
        this.order.surname = this.user.sname;
        this.order.email = this.user.email;
        this.order.phone = this.user.phone_number;
      }
    },
    onInvalidSubmit() {
      this.toast.warning(this.$t('pole'));
    },
    createOrder() {
      if (this.selectedTab === 1 && !this.user.addresses.length && !this.showAddressForm) {
        let currentScrollPos = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
        let targetScrollPos = currentScrollPos - 400;
        window.scrollTo({
          top: targetScrollPos,
          behavior: 'smooth'
        });
        this.selectedTab = 1
        this.toast.warning(this.$t('addPol'));
      } else {
      let formData = new FormData();
      formData.append("name", this.order.name);
      formData.append("surname", this.order.surname);
      formData.append("email", this.order.email);
      formData.append("phone", this.order.phone);
      formData.append("delivery_type", this.order.deliveryType);
      formData.append("delivery_date", this.order.deliveryDate);
      formData.append("payment_type", this.order.payment);
      formData.append("total_price", this.total);
      formData.append("promocode", this.promocode);
      formData.append("comment", this.order.comment);
      formData.append("promocode_type", this.typePromo);
      formData.append("payment_status", "0");
      formData.append(
        "region_id",
        this.selectedAddress.region_id === undefined
          ? ""
          : this.selectedAddress.region_id
      );
      formData.append(
        "city_id",
        this.selectedAddress.city_id === undefined
          ? ""
          : this.selectedAddress.city_id
      );
      formData.append(
        "street",
        this.selectedAddress.address === undefined
          ? ""
          : this.selectedAddress.address
      );
      formData.append(
        "house",
        this.selectedAddress.apartment === undefined
          ? ""
          : this.selectedAddress.apartment
      );
      let ids = [];
      this.freeCount.map((item, i) => {
        formData.append(`products[${i}][id]`, item.id);
        formData.append(`products[${i}][free]`, item.count);
        if (item.typeBuy === 1) {
          formData.append(`products[${i}][count]`, item.quant);
          formData.append(`products[${i}][box_count]`, 0);
          formData.append(`products[${i}][sum]`, item.price * item.quant);
        } else {
          formData.append(`products[${i}][count]`, 0);
          formData.append(`products[${i}][box_count]`, item.quant);
          formData.append(
            `products[${i}][sum]`,
            item.price * item.quant * parseInt(this.cart[i].features.quantity)
          );
        }
        formData.append(`products[${i}][price]`, item.price);
        formData.append(`products[${i}][funds_1]`, item.funds_1);
        ids.push(item.id);
      });
      this.boolCount.map((item, i) => {
        formData.append(`products[${this.freeCount.length + i}][id]`, item.id);

        if (item.typeBuy === 1) {
          formData.append(
            `products[${this.freeCount.length + i}][count]`,
            item.count
          );
          formData.append(
            `products[${this.freeCount.length + i}][box_count]`,
            0
          );
          formData.append(
            `products[${this.freeCount.length + i}][sum]`,
            this.cart[i].price * item.count
          );
        } else {
          formData.append(`products[${this.freeCount.length + i}][count]`, 0);
          formData.append(
            `products[${this.freeCount.length + i}][box_count]`,
            item.count
          );
          formData.append(
            `products[${this.freeCount.length + i}][sum]`,
            this.cart[i].price *
              item.count *
              parseInt(this.cart[i].features.quantity)
          );
        }
        formData.append(
          `products[${this.freeCount.length + i}][funds_2]`,
          item.funds_2
        );
        formData.append(
          `products[${this.freeCount.length + i}][bonus]`,
          item.bonus
        );
        ids.push(item.id);
      });
      for (let i = 0; i < this.cart.length; i++) {
        const even = (element) => element == this.cart[i].id;
        if (!ids.some(even)) {
          formData.append(
            `products[${
              this.freeCount.length + this.boolCount.length + i
            }][id]`,
            this.cart[i].id
          );
          if (this.cart[i].typeBuy === 1) {
            formData.append(
              `products[${
                this.freeCount.length + this.boolCount.length + i
              }][count]`,
              this.cart[i].quantity
            );
            formData.append(
              `products[${
                this.freeCount.length + this.boolCount.length + i
              }][box_count]`,
              0
            );
            formData.append(
              `products[${
                this.freeCount.length + this.boolCount.length + i
              }][sum]`,
              this.cart[i].price * this.cart[i].quantity
            );
          } else {
            formData.append(
              `products[${
                this.freeCount.length + this.boolCount.length + i
              }][count]`,
              0
            );
            formData.append(
              `products[${
                this.freeCount.length + this.boolCount.length + i
              }][box_count]`,
              this.cart[i].quantity
            );
            formData.append(
              `products[${
                this.freeCount.length + this.boolCount.length + i
              }][sum]`,
              this.cart[i].price *
                this.cart[i].quantity *
                this.cart[i].features.quantity
            );
          }
        }
        //     item.id == this.cart[i].id ?
        //     if (item.id == this.cart[i].id) {
        //
        //     }
      }
      axios
        .post("V1/add-order", formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          this.SET_CART([]);
          this.$router.push("/personal-account");
        })
        .catch((error) => {
          this.$router.push("/login");
        });
      }
      
    },
  },
  mounted() {
    this.typeUser = localStorage.getItem("type");
    this.getDate()
  },
  watch: {
    modalShow() {
      return this.modalShow;
    },
    selectedTab: function () {
      if (this.selectedTab === 1) {
        this.order.deliveryType = "delivery";
      } else if (this.selectedTab === 2) {
        this.order.deliveryType = "self";
      }
    },
  },
};
</script>


<style lang="scss" scoped>

button:disabled,
button[disabled]{
  background-color: #e3dfde;
}
.shopping-cart {
  padding: 50px 0 77px 0;
  @media (max-width: $mobileSmall + px) {
    padding: 32px 0 136px 0;
  }

  &__container {
    @media (min-width: $mobile + px) {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 0 50px;
    }
    @media (max-width: $tablet + px) {
      gap: 0 30px;
    }
  }

  &__cart {
    flex: 0 1 669px;
    @media (max-width: $mobile + px) {
      margin: 0 0 93px 0;
    }
  }

  &__order {
    flex: 0 1 570px;
  }

  &__title {
    margin: 0 0 22px 0;
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 38px 0;
    }
  }

  &__items {
    margin: 0 0 49px 0;
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 35px 0;
    }
  }

  &__item {
    &:not(:last-child) {
      margin: 0 0 15px 0;
    }
  }

  &__btn {
    padding: 0 22px;
    @media (max-width: $mobileSmall + px) {
      width: 100%;
    }
  }

  .tab-link {
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    text-transform: capitalize;
  }

  .tab-content {
    padding: 48px 0 0 0;
    @media (max-width: $mobileSmall + px) {
      padding: 36px 0 0 0;
    }
  }
}

.order-cart {
  &__title {
    margin: 0 0 22px 0;
    @media (max-width: $mobileSmall + px) {
      margin: 0 0 34px 0;
    }
  }

  &__second-title {
    font-weight: 500;
    font-size: 22px;
    line-height: 27px;
    margin: 0 0 26px 0;
  }

  &__sub-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin: 0 0 25px 0;
  }

  &__section {
    &:not(:last-child) {
      margin: 0 0 60px 0;
      @media (max-width: $mobileSmall + px) {
        margin: 0 0 40px 0;
      }
    }
  }

  &__block {
    &:not(:last-child) {
      margin: 0 0 50px 0;
      @media (max-width: $mobileSmall + px) {
        margin: 0 0 45px 0;
      }
    }
  }

  &__addresses {
    margin: 0 0 30px 0;
  }

  &__address {
    &:not(:last-child) {
      margin: 0 0 18px 0;
    }
  }

  &__radio {
    cursor: pointer;
    padding: 15px;
    border: 1px solid #000000;
    border-radius: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    display: flex;
    align-items: center;
    text-align: left;
    //white-space: nowrap;

    input {
      display: none;
    }

    @media (max-width: $mobileSmall + px) {
      font-size: 14px;
      padding: 12px 15px;
    }
  }

  &__icon {
    position: relative;
    display: inline-block;
    min-width: 20px;
    min-height: 20px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1.5px solid #000000;
    background: #ffffff;
    margin-right: 10px;
    box-sizing: border-box;
    padding: 3.5px;

    &:after {
      content: "";
      width: 100%;
      height: 100%;
      display: block;
      background: #000000;
      border-radius: 50%;
      transform: scale(0);
    }

    &.active {
      &:after {
        transform: scale(1);
      }
    }
  }

  &__map {
    display: block;
    margin: 0 5px 0 0;
  }

  &__add-address {
    padding: 0 40px;
    margin: 0 0 10px 0;
  }

  &__items {
    margin: 0 0 10px 0;
    @media (min-width: $mobileSmall + px) {
      margin: 0 0 17px 0;
      display: flex;
      align-items: center;
      gap: 15px 20px;
      flex-wrap: wrap;
    }
    @media (max-width: $tablet + px) {
      gap: 12px;
    }
  }

  &__item {
    flex: 0 1 calc(50% - 10px);
    &_firct {
      .select__list {
        z-index: 5;
      }
    }
    &_address {
      flex: 0 1 calc(62.28070175438596% - 10px);
    }

    &_flat {
      flex: 0 1 calc(37.71929824561404% - 10px);
    }

    &:not(:last-child) {
      @media (max-width: $mobileSmall + px) {
        margin: 0 0 20px 0;
      }
    }
  }

  &__example {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: #77757f;
  }

  &__input,
  &__btn,
  &__select,
  &__date,
  &__textarea {
    width: 100%;
  }

  &__select {
    &_first {
      .select__list {
        z-index: 2 !important;
      }
    }
  }

  &__date {
    padding: 13px 15px;
    max-width: 275px;
  }

  &__textarea {
    height: 115px;
    resize: none;
  }

  &__payments {
    @media (min-width: $mobileSmall + px) {
      display: flex;
      gap: 15px 25px;
    }
    @media (max-width: $tablet + px) {
      flex-wrap: wrap;
    }
  }

  &__payment {
    label {
      white-space: nowrap;
      display: inline-block;
      background: #f7f7f7;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #202020;
      padding: 13.5px 16px;

      &.active {
        background: #dadada;
      }
    }

    input {
      display: none;
      gap: 0 25px;
    }

    &:not(:last-child) {
      @media (max-width: $mobileSmall + px) {
        margin: 0 0 15px 0;
      }
    }
  }

  &__checkbox {
    width: 16px;
    height: 16px;
    min-width: 16px;
    min-height: 16px;

    &:after {
      background: url("../assets/icons/shopping-cart/check.svg") center
        no-repeat;
    }
  }

  &__accept {
    margin: 0 0 36px 0;
  }

  &__group {
    display: flex;
    align-items: flex-start;
    gap: 0 9px;
  }

  &__text {
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
  }
}

.shopping-total {
  background: #f7f7f7;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 30px 57px 23px 30px;
  margin: 0 0 36px 0;
  @media (max-width: $tablet + px) {
    padding: 26px 32.5px 28px;
  }
  @media (max-width: $mobileSmall + px) {
    margin: 0 0 34px 0;
  }
  @media (max-width: $mobileSmall + px) {
    padding: 26px 10px 28px;
  }

  &__title {
    @extend .base-text-500;
    margin: 0 0 15px 0;
  }
  &__deliveryText {
    padding-top: 17px;
    display: flex;
    align-items: flex-start;
    gap: 5px;
    color: #727272;
  }

  &__coupon {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 400;
    font-size: 13px;
    line-height: 117%;
    border-radius: 10px;
    overflow: hidden;
    margin: 0 0 18px 0;
  }

  &__input {
    border-radius: 10px;
    background: transparent;
    border: 1px solid #e0e0e0;
    flex: 0 1 100%;
    padding: 14px 15px;
    color: #77757f;
    @media (max-width: $mobileSmall + px) {
      padding: 14px 12px;
    }
  }

  &__btn {
    background: #d81921;
    color: #ffffff;
    padding: 15px 27px 15px 34px;
    @media (max-width: $mobileSmall + px) {
      padding: 15px 18px;
      flex: 0 0 108px;
    }
  }

  &__items {
    padding: 0 0 15px 0;
    margin: 0 0 20px 0;
    border-bottom: 1px solid #cfcfcf;
    @media (max-width: $mobileSmall + px) {
      padding: 0 0 16px 0;
      margin: 0 0 19px 0;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:last-child) {
      margin: 0 0 6px 0;
      @media (max-width: $mobileSmall + px) {
        margin: 0 0 12px 0;
      }
    }
  }

  &__list {
    width: 100%;
    list-style-type: decimal !important;

    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__second-title {
    @extend .base-text-500;
    color: #727272;
    @media (max-width: $tablet + px) {
      font-size: 16px;
    }
    @media (max-width: $mobileSmall + px) {
      font-size: 14px;
    }
  }

  &__amount {
    font-weight: 700;
    font-size: 16px;
    line-height: 117%;
    white-space: nowrap;
  }

  &__total {
    @extend .base-text;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 700;
  }
}
</style>